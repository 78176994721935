import { useMutationState } from '@tanstack/react-query';
import * as yup from 'yup';

import { FlexBox, Link } from '@blockworks/ui/components';
import { Form } from '@blockworks/ui/forms/form';

import { authMutations } from '../../api/mutations';

const loginSchema = yup.object({
    email: yup.string().required('Required').email('Must be a valid email'),
    password: yup.string().required('Required'),
});

type CredentialsSignInProps = {
    forgotPasswordLink?: string;
    callbackUrl?: string;
    onSuccess?: () => void;
};

const CredentialsSignIn = ({ forgotPasswordLink, onSuccess, callbackUrl }: CredentialsSignInProps) => {
    const { handleSubmit, control } = Form.use({
        values: {
            email: '',
            password: '',
        },
        shouldUseNativeValidation: false,
        resolver: Form.resolvers.yup(loginSchema),
    });

    const anySignIn = useMutationState({ filters: { mutationKey: authMutations.signin.use.getKey() } });
    const signInMutation = authMutations.signin.use({
        onSuccess,
    });

    const onSubmit = handleSubmit(async ({ email, password }) => {
        await signInMutation.mutateAsync({
            provider: 'credentials',
            options: { email, password, redirect: true, callbackUrl },
        });
    });

    return (
        <Form onSubmit={onSubmit} className="w-full">
            <FlexBox col gap="xl" w="full">
                <FlexBox col gap="md" w="full">
                    <Form.Input id="name" name="email" control={control} placeholder="Enter your email address" />
                    <Form.Input
                        id="password"
                        name="password"
                        type="password"
                        control={control}
                        placeholder="Enter your password"
                    />
                    {forgotPasswordLink && (
                        <Link intent="primary" size="sm" href={forgotPasswordLink} disabled={signInMutation.isPending}>
                            Forgot password?
                        </Link>
                    )}
                </FlexBox>
                <Form.Submit
                    data-testid="sign-in-button"
                    center
                    loading={signInMutation.isPending}
                    disabled={anySignIn.some(({ status }) => ['pending', 'success'].includes(status))}
                >
                    Sign in
                </Form.Submit>
            </FlexBox>
        </Form>
    );
};

export type { CredentialsSignInProps };

export { CredentialsSignIn };
